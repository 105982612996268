import { ReactNode, FC } from 'react';

interface ISubTitle {
  children: ReactNode;
  className?: string;
  animation?: string;
}

export const Subtitle: FC<ISubTitle> = ({
  children,
  className,
  animation,
}): JSX.Element => {
  return (
    <h3
      className={`font-normal md:font-light text-2xl md:text-[32px] leading-[1.875rem] md:leading-[38px] whitespace-pre-wrap ${className}`}
      {...(animation && { 'data-animation': animation })}
    >
      {children}
    </h3>
  );
};
