import Image from 'next/image';
import { FC, useRef } from 'react';

import { SectionDefinition, ISectionDefinition } from './SectionDefinition';
import { Container } from './Container';
import { getStaticUrl } from '../helpers';
import { useAnimation } from '../hooks';
import { setAnimationConfig } from '../hooks/useAnimation';

export interface ISocialResponsibility {
  sectionDefinition?: ISectionDefinition;
  images?: {
    id: number;
    attributes: {
      url: string;
      width: number;
      height: number;
      alternativeText: string;
    };
  }[];
}

interface IGridImage {
  src: string;
  width: number;
  height: number;
  className: string;
  alt?: string;
  animation?: string;
}

const GridImage: FC<IGridImage> = ({
  src,
  width,
  height,
  className,
  alt,
  animation,
}) => {
  return (
    <div
      className={`${className}`}
      {...(animation && { 'data-animation': animation })}
    >
      <Image
        className="lg:rounded-lg"
        src={src}
        layout="responsive"
        width={width}
        height={height}
        alt={alt}
      />
    </div>
  );
};

export const SocialResponsibility: FC<ISocialResponsibility> = ({
  sectionDefinition,
  images,
}) => {
  const classNames = [
    'col-start-1 col-end-[16] row-start-4 row-end-[8] lg-:z-10 lg:col-start-4 lg:col-end-[15] lg:row-start-1 lg:row-end-4',
    'col-start-12 col-end-[25] lg:col-end-[21] row-start-3 row-end-6',
    'col-start-3 col-end-[13] row-start-4 row-end-[8] lg-:hidden',
  ];
  const containerRef = useRef(null);
  useAnimation({
    containerRef,
  });

  return (
    <Container
      className="flex items-center mb-20 lg-:flex-col-reverse lg-:px-0"
      ref={containerRef}
    >
      <div className="lg:w-1/2 lg:pr-32 lg-:px-6">
        {sectionDefinition && (
          <SectionDefinition
            title={sectionDefinition.title}
            subtitle={sectionDefinition.subtitle}
            description={sectionDefinition.description}
            cta={sectionDefinition.cta}
          />
        )}
      </div>
      <div className="w-full grid grid-cols-[repeat(24,minmax(0,1fr))] auto-rows-min lg:w-1/2 lg-:pb-6">
        {images?.length &&
          images.map((image, index) => (
            <GridImage
              src={getStaticUrl(image.attributes.url)}
              width={image.attributes.width}
              height={image.attributes.height}
              alt={image.attributes.alternativeText}
              key={image.attributes.url}
              className={`invisible ${classNames[index]}`}
              animation={setAnimationConfig({
                type: 'clip',
                delay: 8 + index,
                duration: 4,
                from: 'right',
              })}
            />
          ))}
      </div>
    </Container>
  );
};
