import { FC, ReactNode } from 'react';

interface ITitle {
  children: ReactNode;
  className?: string;
  animation?: string;
}

export const Title: FC<ITitle> = ({ children, className, animation }) => {
  return (
    <h2
      className={`w-full text-[2.125rem] md:text-[40px] leading-[50px] md:leading-[76px] font-bold tracking-[-1px] ${className}`}
      {...(animation && { 'data-animation': animation })}
    >
      {children}
    </h2>
  );
};
