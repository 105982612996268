export const addRedSpan = {
  type: 'lang',
  regex: /\*red\*(\S[\s\S]*?)\*red\*/g,
  replace: function (a: string, b: string) {
    return '<span class="text-guardsman-red">' + b + '</span>';
  },
};

export const removeParagraph = {
  type: 'output',
  regex: new RegExp(`<\\/?p[^>]*>`, 'g'),
  replace: '',
};
