import { ElementType, FC, ReactNode } from 'react';
import ArrowIcon from '/public/static/CTA-arrow.svg';

export interface ICTA {
  as?: ElementType;
  url?: string;
  children: ReactNode;
  className?: string;
  scheme?: 'blue' | 'white' | 'mona-lisa';
  compact?: boolean;
  animation?: string;
}

export const CTA: FC<ICTA> = ({
  as: Tag = 'a',
  url = '#',
  children,
  className = '',
  scheme = 'blue',
  compact = false,
  animation,
}) => {
  let anchorClassName = className;
  let arrowClassName = '';

  switch (scheme) {
    case 'white':
      anchorClassName += ` ${!compact && 'border-white'} text-white`;
      arrowClassName += ' fill-white';
      break;
    case 'mona-lisa':
      anchorClassName += ` ${!compact && 'border-[#ff6868]'} text-[#ff6868]`;
      arrowClassName += ' fill-[#ff6868]';
      break;
    default:
      anchorClassName += ` ${
        !compact && 'border-blue-ribbon hover:border-guardsman-red'
      } ease-in duration-300 text-blue-ribbon hover:text-guardsman-red`;
      arrowClassName +=
        'fill-blue-ribbon ease-in duration-300 group-hover:fill-guardsman-red';
  }

  return (
    <Tag
      className={`group inline-flex items-center ease-in duration-300 ${
        !compact ? 'border-b-[1px] py-3 tracking-[2px]' : 'text-sm'
      } uppercase ${anchorClassName}`}
      {...(Tag === 'a' && { href: url })}
      {...(animation && { 'data-animation': animation })}
    >
      {children}
      <span className={`${!compact ? 'ml-5' : 'ml-2'}`}>
        <ArrowIcon className={arrowClassName} />
      </span>
    </Tag>
  );
};
