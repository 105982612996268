import type { GetStaticProps } from 'next';
import type { NextPage } from 'next';
import { useCallback } from 'react';
import { useRef } from 'react';

import { AboutUs } from '../components/AboutUs';
import { Companies } from '../components/Companies';
import { Careers } from '../components/Careers';
import { Hero } from '../components/Hero';
import { NewsAndEvents } from '../components/NewsAndEvents';
import { Owner } from '../components/Owner';
import { SocialResponsibility } from '../components/SocialResponsibility';

import { ApiHomePageHomePage } from '../types/strapi/contentTypes';
import { IAboutResponse } from '../types';
import { ICareersSectionResponse } from '../types';
import { ICompaniesAndProductsResponse } from '../types';
import { IHeroResponse } from '../types';
import { INewsAndEvents } from '../components/NewsAndEvents';
import { IOwner } from '../components/Owner';
import { ISocialResponsibility } from '../components/SocialResponsibility';

import { addSiteUrlToPath } from '../helpers';
import { getCookieConsent } from '../helpers/strapi-client';
import { getNews } from '../helpers/strapi-client';
import { getPageData } from '../helpers/strapi-client';
import { getStaticUrl } from '../helpers';
import { sendEvent } from '../helpers';
import { useCareersData } from '../hooks';
import { useRegisterWindowEvent } from '../hooks';

interface IHome {
  hero: IHeroResponse;
  aboutUs: IAboutResponse;
  socialResponsibility: ISocialResponsibility;
  companiesAndProducts: ICompaniesAndProductsResponse;
  owner: IOwner;
  careersSection: ICareersSectionResponse;
  newsAndEvents: INewsAndEvents;
}

const Home: NextPage<IHome> = ({
  hero: {
    title,
    subtitle,
    description,
    thumbnail: {
      data: {
        attributes: { url },
      },
    },
    loop_video,
    overlay_video,
  },
  aboutUs,
  socialResponsibility,
  companiesAndProducts,
  owner,
  careersSection,
  newsAndEvents,
}) => {
  const overlayUrl = overlay_video?.data.attributes.url ?? '';
  const loopUrl = loop_video?.data.attributes.url ?? '';
  const heroVideoRef = useRef<HTMLVideoElement>(null);
  const { careers } = useCareersData();

  const onPlayHeroVideo = () => heroVideoRef.current?.play();

  const onOpenVideoOverlay = useCallback(() => {
    heroVideoRef.current?.pause();
    sendEvent({
      eventName: 'openVideoOverlay',
      detail: {
        url: getStaticUrl(overlayUrl, true),
        thumbnail: getStaticUrl(url, true),
      },
    });
  }, [heroVideoRef, overlayUrl, url]);

  useRegisterWindowEvent('closeVideoOverlay', onPlayHeroVideo);

  const heroMedia = (
    <>
      <video
        ref={heroVideoRef}
        src={getStaticUrl(loopUrl, true)}
        poster={getStaticUrl(url, true)}
        playsInline
        autoPlay
        loop
        muted
        className="absolute min-w-full min-h-full object-cover top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
      <div className="absolute w-full h-full flex justify-center items-center z-10">
        <img
          width={120}
          height={120}
          src="/static/PlayButton.svg"
          alt="PlayButton"
          onClick={onOpenVideoOverlay}
          className="cursor-pointer"
        />
      </div>
    </>
  );

  return (
    <>
      <Hero
        className="mb-[6.625rem]"
        media={heroMedia}
        title={title}
        subtitle={subtitle}
        extraInfo={description}
      />
      <AboutUs {...aboutUs} />
      <Companies {...companiesAndProducts} />
      <SocialResponsibility {...socialResponsibility} />
      <Owner {...owner} />
      {careers && <Careers careers={careers} {...careersSection} />}
      <div className="mb-20">
        <NewsAndEvents {...newsAndEvents} />
      </div>
    </>
  );
};

export const getStaticProps: GetStaticProps<IHome> = async () => {
  const pageData = await getPageData<ApiHomePageHomePage>('home-page', [
    'about_us.image',
    'about_us.cta',
    'about_us.fact_counts',
    'companies_and_products',
    'companies_and_products',
    'companies_and_products.cta',
    'companies_and_products.cta',
    'companies_and_products.company_entities',
    'companies_and_products.company_entities.logo',
    'companies_and_products.company_entities.basic_logo',
    'social_responsibility',
    'social_responsibility.cta',
    'social_responsibility_images',
    'owner',
    'owner.image',
    'careers',
    'careers.cta',
    'careers.image',
    'routes',
    'footer.map_image',
    'footer.company_entities',
    'footer.legal_documents.document',
    'news_and_events',
    'news_and_events.cta',
    'hero.thumbnail',
    'hero.loop_video',
    'hero.overlay_video',
  ]);

  const newsEntities = await getNews(8, 1);
  const cookieConsentData = await getCookieConsent();

  const newsAndEvents = {
    ...pageData?.attributes.news_and_events,
    data: newsEntities,
  };

  const aboutUs = {
    image: pageData?.attributes.about_us.image,
    cta: pageData?.attributes.about_us.cta,
    description: pageData?.attributes.about_us.description,
    factsCounts: pageData?.attributes.about_us.fact_counts.data,
  };

  const companiesAndProducts = {
    sectionDefinition: {
      title: pageData?.attributes.companies_and_products.title,
      subtitle: pageData?.attributes.companies_and_products.subtitle,
      description: pageData?.attributes.companies_and_products.description,
      cta: pageData?.attributes.companies_and_products.cta,
    },
    items: pageData?.attributes.companies_and_products.company_entities.data,
  };

  const socialResponsibility = {
    sectionDefinition: pageData?.attributes.social_responsibility,
    images: pageData?.attributes.social_responsibility_images.data,
  };

  const footer = {
    company_details: pageData.attributes.footer,
    facebook_url: pageData.attributes.footer.facebook_url,
    twitter_url: pageData.attributes.footer.twitter_url,
    instagram_url: pageData.attributes.footer.instagram_url,
    linkedin_url: pageData.attributes.footer.linkedin_url,
    company_name: pageData.attributes.footer.company_name,
    companies_title: pageData.attributes.footer.companies_title,
    legal_documents_title: pageData.attributes.footer.legal_documents_title,
    company_entities: pageData.attributes.footer.company_entities,
    map_image: pageData.attributes.footer.map_image.data,
    legal_documents: pageData.attributes.footer.legal_documents.data,
  };

  const head = {
    title: pageData?.attributes.hero.data.attributes.title,
    description: pageData?.attributes.hero?.data.attributes.subtitle,
    image: {
      url: addSiteUrlToPath(
        getStaticUrl(
          pageData?.attributes.hero?.data.attributes.thumbnail?.data.attributes
            .url
        )
      ),
      width:
        pageData?.attributes.hero?.data.attributes.thumbnail?.data.attributes
          .width,
      height:
        pageData?.attributes.hero?.data.attributes.thumbnail?.data.attributes
          .height,
    },
  };

  return {
    props: {
      careersSection: pageData?.attributes.careers,
      hero: pageData?.attributes.hero.data.attributes,
      routes: pageData?.attributes.routes.data,
      owner: pageData?.attributes.owner,
      aboutUs,
      companiesAndProducts,
      cookieConsentData,
      footer,
      head,
      newsAndEvents,
      socialResponsibility,
    },
  };
};

export default Home;
