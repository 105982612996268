import Image from 'next/image';
import { FC, useRef } from 'react';
import { CTA } from './CTA';
import { Container } from './Container';
import { Title } from './Title';
import { useAnimation } from '../hooks';
import { setAnimationConfig } from '../hooks/useAnimation';
import { ICTAResponse, IImageResponse } from '../types';
import { getStaticUrl } from '../helpers';

interface ICareer {
  id: string;
  name: string;
  location: { city: string };
  department: { id: string; label: string };
}

export interface ICareers {
  careers: ICareer[];
  image: IImageResponse;
  cta: ICTAResponse;
  title: string;
}
interface IProfessionWithLocation {
  profession: string;
  location: string;
  department?: string;
  animation?: string;
}

const ProfessionWithLocation: FC<IProfessionWithLocation> = ({
  profession,
  department,
  location,
  animation,
}) => (
  <div
    className="flex flex-col justify-start border-b border-[#E4E4F5] invisible"
    {...(animation && { 'data-animation': animation })}
  >
    <div className="uppercase text-lg tracking-wider+ leading-tight">
      {profession}
      {department && `, ${department}`}
    </div>
    <div className="uppercase text-[#CB202C] pb-4 text-sm tracking-widest">
      {location}
    </div>
  </div>
);

export const Careers: FC<ICareers> = ({ careers, title, image, cta }) => {
  const containerRef = useRef(null);
  useAnimation({
    containerRef,
  });

  return (
    <Container className="flex mb-20" ref={containerRef}>
      <div className="flex-1 basis-1/2 lg:pr-44 invisible">
        <Title
          animation={setAnimationConfig({
            type: 'clip',
            duration: 4,
            from: 'bottom',
          })}
        >
          {title}
        </Title>
        <div className="flex flex-col gap-4 mt-8">
          {careers.map(({ id, name, department, location }, index) => (
            <ProfessionWithLocation
              key={id}
              profession={name}
              department={department.label}
              location={location.city}
              animation={setAnimationConfig({
                type: 'fade',
                delay: 2 + index,
                duration: 2,
              })}
            />
          ))}
        </div>
        {cta && (
          <CTA
            className="mr-auto mb-0 mt-6 invisible"
            url={cta.url}
            animation={setAnimationConfig({
              type: 'slide-in-fade',
              delay: 15,
              duration: 4,
              from: 'left',
            })}
          >
            {cta.text}
          </CTA>
        )}
      </div>
      {image && (
        <div
          className="relative flex-1 basis-1/2 lg-:hidden invisible"
          data-animation={setAnimationConfig({
            type: 'clip',
            delay: 8,
            duration: 4,
            from: 'right',
          })}
        >
          <Image
            src={getStaticUrl(image.data.attributes.url)}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt="img_4"
          />
        </div>
      )}
    </Container>
  );
};
