import { CTA } from './CTA';
import { AmountWithName } from './AmountWithName';
import Image from 'next/image';
import { Container } from './Container';
import { FC, useRef } from 'react';
import { useAnimation, useWindowDimensions } from '../hooks';
import { setAnimationConfig } from '../hooks/useAnimation';
import { IAboutResponse } from '../types';
import { getStaticUrl } from '../helpers';

export const AboutUs: FC<IAboutResponse> = ({
  description,
  cta,
  image,
  factsCounts,
}) => {
  const {
    breakPoints: { 'md-': isMobile },
  } = useWindowDimensions();
  const containerRef = useRef(null);
  useAnimation({
    containerRef,
  });
  return (
    <Container
      className="flex mb-16 md:mb-20 overflow-hidden md-:px-0 md-:flex-col"
      ref={containerRef}
    >
      {!isMobile && (
        <div
          className="relative flex-1 invisible"
          data-animation={setAnimationConfig({
            type: 'clip',
            duration: 4,
            from: 'left',
            delay: 10,
          })}
        >
          {image && (
            <Image
              src={getStaticUrl(image.data.attributes.url as string)}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              alt={image.data.attributes.alternativeText as string}
            />
          )}
        </div>
      )}
      <div className="flex-1 w-full">
        <div className="flex flex-col md:pl-[3.625rem] md:pr-[2.25rem]">
          <p
            className="text-[25px] font-light leading-[30px] md-:px-6 md-:pb-[27px] md:text-[2rem] md:leading-[2.375rem] invisible"
            data-animation={setAnimationConfig({
              type: 'slide-in-fade',
              delay: 4,
              duration: 4,
              from: 'left',
            })}
          >
            {description}
          </p>
          <div
            className="flex invisible"
            data-animation={setAnimationConfig({
              type: 'slide-in-fade',
              delay: 6,
              duration: 4,
              from: 'left',
            })}
          >
            {isMobile && (
              <div
                className="relative w-full invisible"
                data-animation={setAnimationConfig({
                  type: 'clip',
                  duration: 4,
                  from: 'left',
                })}
              >
                <Image
                  src={getStaticUrl(image.data.attributes.url as string)}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  alt={image.data.attributes.alternativeText as string}
                />
              </div>
            )}
            <div className="flex flex-col w-full md-:pl-6 md-:pr-[37px] md-:w-[60%]">
              <div className="flex gap-8 justify-between pt-[3rem] pb-5 md:pb-[2.25rem] md-:flex-col md:w-full md:gap-2">
                {factsCounts.map(({ attributes: { fact, count } }) => (
                  <AmountWithName key={fact} amount={count} text={fact} />
                ))}
              </div>
              <CTA
                className="mt-auto mr-auto invisible"
                url={cta.url}
                animation={setAnimationConfig({
                  type: 'slide-in-fade',
                  delay: 8,
                  duration: 4,
                  from: 'left',
                })}
              >
                {isMobile ? cta.mobile_text : cta.text}
              </CTA>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
