import { FC, ReactNode } from 'react';

interface IDescription {
  children: ReactNode;
  className?: string;
  animation?: string;
}

export const Description: FC<IDescription> = ({
  children,
  className,
  animation,
}) => {
  return (
    <p
      className={`text-[20px] leading-[24px] font-light ${className}`}
      {...(animation && { 'data-animation': animation })}
    >
      {children}
    </p>
  );
};
