import Image from 'next/image';
import { Container } from './Container';
import { FC, useRef } from 'react';
import { useAnimation, useWindowDimensions } from '../hooks';
import { ISectionDefinition, SectionDefinition } from './SectionDefinition';
import { setAnimationConfig } from '../hooks/useAnimation';
import { CTA } from './CTA';
import { getStaticUrl } from '../helpers';
import { ICompaniesCard } from './pages/companies';
import { IImageResponse } from '../types';
import { ApiCompanyCompany } from '../types/strapi/contentTypes';

export interface ICompanyEntities extends ICompaniesCard {
  showOnMobile: boolean;
  basicLogo: IImageResponse;
}

export interface ICompanies {
  sectionDefinition: ISectionDefinition;
  items: ApiCompanyCompany[];
}

export const Companies: FC<ICompanies> = ({ sectionDefinition, items }) => {
  const {
    breakPoints: { 'md-': isMobile },
  } = useWindowDimensions();
  const containerRef = useRef(null);
  useAnimation({
    containerRef,
  });

  return (
    <Container className="mb-16 text-center" ref={containerRef}>
      <div className="grid grid-cols-2 md:grid-cols-4 auto-rows-150 text-left md:gap-x-px md:gap-y-px md:bg-[#E4E4F5]">
        <div className="bg-white relative col-start-1 col-span-2 row-start-1 row-span-1 md:p-2 md:row-span-3 flex align-center">
          {sectionDefinition && (
            <SectionDefinition
              title={sectionDefinition.title}
              subtitle={sectionDefinition.subtitle}
              description={sectionDefinition.description}
              cta={!isMobile ? sectionDefinition.cta : undefined}
            />
          )}
        </div>
        {items
          .filter(
            ({ attributes: { show_on_mobile } }) => show_on_mobile === isMobile
          )
          .map(({ attributes: { basic_logo, name } }, index) => (
            <div key={index} className="w-full h-full bg-white md-:p-2">
              <div
                className="h-full w-full relative flex align-middle justify-center p-2 invisible"
                data-animation={setAnimationConfig({
                  type: 'fade',
                  delay: 8 + index,
                  duration: 2,
                })}
              >
                <Image
                  layout="fill"
                  objectFit="contain"
                  src={getStaticUrl(basic_logo?.data.attributes.url)}
                  alt={name}
                />
              </div>
            </div>
          ))}
      </div>
      {isMobile && (
        <CTA className="!flex w-fit" url={sectionDefinition?.cta?.url}>
          {sectionDefinition?.cta?.text}
        </CTA>
      )}
    </Container>
  );
};
