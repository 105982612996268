import { FC } from 'react';

interface IAmountWithName {
  amount: string | number;
  text: string;
}

export const AmountWithName: FC<IAmountWithName> = ({ amount, text }) => (
  <div className="flex flex-col justify-center items-start lg:items-center">
    <span className="text-[#da0000] text-[42px] lg:text-5.5xl leading-tighter">
      {amount}
    </span>
    <span className="uppercase tracking-wider+ text-lg lg:text-1.5xl leading-tighter">
      {text}
    </span>
  </div>
);
