import { FC, useRef } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { useAnimation, useSlidesOffset, useWindowDimensions } from '../hooks';
import { Title } from './Title';
import { Container } from './Container';
import { CTA } from './CTA';
import { setAnimationConfig } from '../hooks/useAnimation';
import { ICTAResponse, IImageResponse } from '../types';
import { trimString } from '../helpers';
import ArrowIcon from '/public/static/CTA-arrow.svg';
import { getLanguageSpecificRoute } from '../helpers/routing';

interface INewsAndEventsItem {
  animation?: string;
  attributes: {
    thumbnail: IImageResponse;
    type: string;
    title: string;
    linkTitle: string;
    slug: string;
    summary?: string;
  };
}

export interface INewsAndEvents {
  title: string;
  cta?: ICTAResponse;
  data: INewsAndEventsItem[] | null;
}

const NewsAndEventsItem: FC<INewsAndEventsItem> = ({
  animation,
  attributes: { thumbnail, summary, linkTitle, title, slug },
}) => {
  return (
    <Link
      href="/[news]/[slug]"
      as={`/${getLanguageSpecificRoute('news')}/${slug}`}
    >
      <a
        className="rounded-md group overflow-hidden min-h-[370px] flex relative before:backface-hidden after:backface-hidden before:z-10 before:bg-red-1 before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0 after:bg-black-gradient after:z-20 after:absolute after:top-1/2 after:left-0 after:right-0 after:bottom-0 after:transition-all after:duration-500 hover:after:top-1/3 invisible"
        {...(animation && { 'data-animation': animation })}
      >
        <span className="absolute top-0 bottom-0 left-0 right-0">
          {thumbnail && (
            <Image
              src={thumbnail.data.attributes.url}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              alt={thumbnail.data.attributes.alternativeText}
              className="transition-transform group-hover:scale-110 duration-500"
            />
          )}
        </span>
        <span className="p-6 relative flex-1 z-30 self-end">
          {linkTitle && (
            <h5 className="tracking-wider+ text-sm text-[#FF9090] uppercase">
              {linkTitle}
            </h5>
          )}
          {title && (
            <h2 className="text-2xl text-white font-semibold mt-1">{title}</h2>
          )}
          {summary && (
            <p className="text-sm text-white font-light mt-1">
              {trimString(summary)}
            </p>
          )}
          <div className="h-0 group-hover:mt-2 transition-all duration-500 opacity-0 group-hover:h-[24px] group-hover:opacity-100">
            <CTA as="span" compact={true} scheme="mona-lisa">
              {process.env.NEXT_PUBLIC_SITE_LANG === 'hu'
                ? 'Tudj meg többet'
                : 'Read more'}
            </CTA>
          </div>
        </span>
      </a>
    </Link>
  );
};

export const NewsAndEvents: FC<INewsAndEvents> = ({ title, cta, data }) => {
  const {
    breakPoints: { 'lg-': isMobile },
  } = useWindowDimensions();
  const containerRef = useRef(null);
  useAnimation({
    containerRef,
  });
  const slidesOffset = useSlidesOffset();
  const nextElRef = useRef<HTMLDivElement>(null);
  const prevElRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef}>
      <Container>
        <div className="flex justify-between items-center">
          <Title
            className="lg:max-w-[75%] invisible"
            animation={setAnimationConfig({
              type: 'clip',
              duration: 4,
              from: 'bottom',
            })}
          >
            {title}
          </Title>
          {!isMobile && cta && (
            <CTA
              url={cta.url}
              className="invisible"
              animation={setAnimationConfig({
                type: 'slide-in-fade',
                delay: 2,
                duration: 4,
                from: 'right',
              })}
            >
              {cta.text}
            </CTA>
          )}
        </div>
      </Container>
      {data && (
        <div className="mt-6 lg-:mb-3 relative">
          <Swiper
            modules={[Navigation]}
            slidesPerView="auto"
            spaceBetween={isMobile ? 16 : 56}
            speed={1000}
            slidesPerGroupAuto={true}
            slidesOffsetBefore={slidesOffset}
            slidesOffsetAfter={slidesOffset}
            navigation={{
              nextEl: nextElRef.current!,
              prevEl: prevElRef.current!,
              disabledClass: 'hidden',
              hiddenClass: 'hidden',
            }}
          >
            <div
              ref={prevElRef}
              className="tap-highlight-transparent w-[80px] z-10 cursor-pointer absolute top-0 left-0 bottom-0 bg-gradient-to-r from-black top-transparent flex justify-center items-center"
            >
              <ArrowIcon fill="white" className="rotate-180" />
            </div>
            {data.map((item, index) => (
              <SwiperSlide
                key={item?.attributes.slug}
                className="max-w-[286px]"
              >
                <NewsAndEventsItem
                  {...item}
                  animation={setAnimationConfig({
                    type: 'fade',
                    delay: 4 + index,
                    duration: 2,
                  })}
                />
              </SwiperSlide>
            ))}
            <div
              ref={nextElRef}
              className="tap-highlight-transparent w-[80px] z-10 cursor-pointer absolute top-0 right-0 bottom-0 bg-gradient-to-r from-transparent to-black flex justify-center items-center"
            >
              <ArrowIcon fill="white" />
            </div>
          </Swiper>
        </div>
      )}
      <Container>
        {isMobile && cta && (
          <CTA
            url={cta.url}
            className="invisible"
            animation={setAnimationConfig({
              type: 'slide-in-fade',
              delay: 2,
              duration: 8,
              from: 'left',
            })}
          >
            {cta.text}
          </CTA>
        )}
      </Container>
    </div>
  );
};
