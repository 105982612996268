import { FC } from 'react';
import { Title } from './Title';
import { Subtitle } from './Subtitle';
import { Description } from './Description';
import { CTA } from './CTA';
import { setAnimationConfig } from '../hooks/useAnimation';
import { ICTAResponse } from '../types';

export interface ISectionDefinition {
  title?: FC | string;
  subtitle?: FC | string;
  description?: FC | string;
  cta?: ICTAResponse;
}

export const SectionDefinition: FC<ISectionDefinition> = ({
  title,
  subtitle,
  description,
  cta,
}) => {
  return (
    <div>
      {title && (
        <Title
          className="md:!leading-[50px] invisible"
          animation={setAnimationConfig({
            type: 'clip',
            delay: 4,
            duration: 4,
            from: 'bottom',
          })}
        >
          {title}
        </Title>
      )}
      {subtitle && (
        <Subtitle
          className="mt-2 invisible"
          animation={setAnimationConfig({
            type: 'slide-in-fade',
            delay: 8,
            duration: 4,
            from: 'left',
          })}
        >
          {subtitle}
        </Subtitle>
      )}
      {description && (
        <Description
          className="mt-3 invisible"
          animation={setAnimationConfig({
            type: 'slide-in-fade',
            delay: 10,
            duration: 4,
            from: 'left',
          })}
        >
          {description}
        </Description>
      )}
      {cta && (
        <CTA
          url={cta.url}
          className="mt-1 invisible"
          animation={setAnimationConfig({
            type: 'slide-in-fade',
            delay: 12,
            duration: 4,
            from: 'left',
          })}
        >
          {cta.text}
        </CTA>
      )}
    </div>
  );
};
