import Image from 'next/image';
import { Container } from './Container';
import { useWindowDimensions } from '../hooks';
import { FC, useRef } from 'react';
import { useAnimation } from '../hooks';
import { setAnimationConfig } from '../hooks/useAnimation';
import { getStaticUrl } from '../helpers';
import { IImageResponse } from '../types';
import Signature from '/public/static/Signature.svg';

export interface IOwner {
  image: IImageResponse;
  name: string;
  description: string;
  position: string;
}

export const Owner: FC<IOwner> = ({ image, name, description, position }) => {
  const {
    breakPoints: { 'lg-': isMobile },
  } = useWindowDimensions();

  const containerRef = useRef(null);
  useAnimation({
    containerRef,
  });

  return (
    <Container
      fullWidth={isMobile}
      className="mb-[90px] mt-[90px] lg:mt-[120px]"
      ref={containerRef}
    >
      <div className="flex items-end relative lg-:px-6">
        <div
          className="z-10 absolute right-0 lg:left-0 lg-:bottom-[-6px] bottom-0 w-[75%] lg:w-[42%] px-5 invisible"
          data-animation={setAnimationConfig({
            type: 'slide-in-fade',
            duration: 4,
            from: 'left',
          })}
        >
          {image && (
            <Image
              src={getStaticUrl(image.data.attributes.url)}
              width={isMobile ? 434 : 1134}
              height={isMobile ? 351 : 916}
              layout={isMobile ? 'fixed' : 'responsive'}
              objectFit="fill"
              objectPosition={isMobile ? 'right bottom' : 'left bottom'}
              alt={image.data.attributes.alternativeText}
            />
          )}
        </div>
        <div
          className="lg:pl-[42%] before:bg-quoteSmall lg:before:bg-quoteBig before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:z-0 invisible"
          data-animation={setAnimationConfig({
            type: 'fade',
            delay: 2,
            duration: 4,
          })}
        >
          <div className="lg-:pb-[11rem] pt-[3.125rem] pb-8 lg:pl-14 z-10 relative w-full before:bg-quote-sign before:absolute before:top-0 lg:before:top-[3.5rem] before:left-0 before:w-[42px] before:h-[32px] before:bg-contain">
            <p className="italic font-light text-xl mt-0 mb-6 ">
              {description}
              <span className="relative top-1.5 ml-2">
                <Image
                  src={getStaticUrl('/Quote.png')}
                  width={24}
                  height={18}
                  className="rotate-180"
                  alt=""
                />
              </span>
            </p>
            <div className="flex lg-:flex-col">
              <div className="lg:mr-[91px]">
                <p className="text-xl leading-6 mt-0 mb-2.5">{name}</p>
                <p className="text-xs leading-[0.875rem] mt-0 lg-:mb-12 mb-0 uppercase">
                  {position}
                </p>
              </div>
              <Signature width={172} height={43} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
